@font-face {
  font-family: 'MetaProBold';
  src: url('./fonts/metaPro/FFMetaProBold.TTF') format('truetype');
}

@font-face {
  font-family: 'Calibri';
  src: url('./fonts/Calibri/calibril.ttf') format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.recaptcha-wrapper {
  height: 70px;
  overflow: hidden;
  background-color: #f9f9f9;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  height: 70px;
  position: relative;
  margin-top: 17px;
  border: 1px solid #d3d3d3;
  color: #000;
}

.recaptcha-info {
  background-size: 32px;
  height: 32px;
  margin: 0 13px 0 13px;
  position: absolute;
  right: 8px;
  top: 9px;
  width: 32px;
  background-image: url(https://www.gstatic.com/recaptcha/api2/logo_48.png);
  background-repeat: no-repeat;
}
.rc-anchor-logo-text {
  color: #9b9b9b;
  cursor: default;
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 37px;
}
.rc-anchor-checkbox-label {
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  left: 50px;
  top: 26px;
  position: absolute;
  color: black;
}
.MuiTooltip-tooltipPlacementTop.MuiTooltip-tooltipArrow{
  top: 12px;
  left: 2px;
  position: relative;

   background-color: initial !important;
   box-shadow: initial !important
}
.css-kudwh-MuiTooltip-arrow{ 
  color: #d4d4d4 !important;
}
.rc-anchor .rc-anchor-normal .rc-anchor-light {
  border: none;
}
.rc-anchor-pt {
  color: #9b9b9b;
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 8px;
  font-weight: 400;
  right: 10px;
  top: 53px;
  position: absolute;
  a:link {
    color: #9b9b9b;
    text-decoration: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

g-recaptcha {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.g-recaptcha {
  width: 41px;

  /* border: 1px solid red; */
  height: 38px;
  overflow: hidden;
  float: left;
  margin-top: 16px;
  margin-left: 6px;

  > div {
    width: 46px;
    height: 30px;
    background-color: #f9f9f9;
    overflow: hidden;
    border: 1px solid red;
    transform: translate3d(-8px, -19px, 0px);
  }
  div {
    border: 0;
  }
}

.captcha {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 1rem;
  background-color: red;
}

@media screen and (max-width: 480px) {
  .captcha {
    width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .captcha {
    width: 80%;
  }
}

@media screen and (max-width: 375px) {
  .captcha {
    width: 93%;
  }
}

@media screen and (max-width: 375px) {
  .captcha {
    width: 100%;
    float: left;
  }
}
